<template>
  <v-container>
    <v-row class="gutter-lg">
      <v-col sm="6" cols="12" class="text-justify pr-sm-12">
        <h1 class="accent--text text-center mb-10">
          Evidensia Dierenziekenhuis Nieuwegein
        </h1>
        De dierenartsen en paraveterinairen van het Evidensia Dierenziekenhuis
        Nieuwegein staan 24 uur per dag, 7 dagen in de week klaar voor
        spoedpatiënten, diereigenaren en verwijzende dierenartsen. We behandelen
        uitsluitend gezelschapsdieren. De spoedkliniek is in 2019 ontstaan
        vanuit de behoefte van de regionale dierenartsen om de spoeddiensten te
        centraliseren in een modern en goed ingericht dierenziekenhuis. Tijdens
        kantooruren is er tevens de mogelijkheid om met uw dier verwezen te
        worden door uw dierenarts naar onze verwijskliniek. Deze wordt bemand
        door (Europees erkende) specialisten en gedifferentieerde hoogopgeleide
        dierenartsen en paraveterinairen.

        <h1 class="accent--text text-center mt-10">
          Ons beleid omtrent COVID-19
        </h1>
        <h3 class="accent--text mt-10">
          Beste collega dierenartsen en diereigenaren,
        </h3>
        Als Dierenziekenhuis blijven ook wij onveranderd 24/7 open en zorg
        dragen voor onze veterinaire patiënten. We zullen doorgaan met ons
        dagelijks 100% in te zetten voor het welzijn van honden en katten om
        hierdoor onze regiofunctie als verwijs- en 24/7-spoedkliniek te blijven
        vervullen. Onze dagelijkse bezetting op de werkvloer kan, door onder
        andere de maatregel waardoor verkouden mensen thuis moeten blijven,
        acuut veranderen. Dit wordt momenteel door ons geweldige team
        fantastisch opgevangen. We zullen bewerkstelligen de dieren en eigenaren
        alsnog te helpen. Wellicht kan het wel voorkomen dat een consult bij een
        discipline van de verwijskliniek verplaatst wordt of dat we in uiterste
        situatie naar een ander dierenziekenhuis moeten verwijzen. Dit beperken
        wij tot een minimum. We vragen hierbij uw begrip. Vooralsnog is de 24/7
        bezetting van de opname en spoedkliniek dankzij de flexibiliteit en
        tomeloze inzet van ons team niet in gevaar gekomen. Het zou echter niet
        ondenkbaar zijn dat we in de toekomst op enkele van onze klinieken in de
        regio een beroep moeten doen als de uitval verder door gaat zetten. Ook
        dit zal tot een minimum beperkt worden en ook hiervoor vragen we alvast
        uw begrip. Mocht u vragen hebben over de beschikbaarheid van een
        specialist of discipline kunt u via
        <span class="accent--text text-decoration-underline"
          >030 - 202 70 70</span
        >
        en
        <span class="accent--text text-decoration-underline"
          >info@edz-nieuwegein.nl</span
        >
        contact met ons opnemen. Beste verwijzer, mocht u uw openingstijden gaan
        aanpassen of zelfs dicht gaan en zodoende mogelijk een extra beroep op
        onze spoeddienst doen, vragen we u vriendelijk dit via een mail aan
        <span class="accent--text text-decoration-underline"
          >info@edz-nieuwegein.nl</span
        >
        kenbaar te maken. Dit zorgt dat ook wij indien nodig passende
        maatregelen voor onze bezetting kunnen nemen. Welke maatregelen nemen we
        nu? De maatregelen van de overheid en informatie over het virus staan
        helder uiteengezet op de website van het RIVM. Deze onderschrijven wij.
        We moeten hierop aanvullende hygiënemaatregelen treffen om besmettingen
        tussen eigenaren van de dieren en ons team almede onderling te
        voorkomen.
      </v-col>
      <v-col sm="6" cols="12">
        <ArticleList />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ArticleList from "@/components/ArticleList";

export default {
  components: {
    ArticleList,
  },
};
</script>

<style>
</style>