import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        primary: '#FFFFFF',
        secondary: '#6a6a6a',
        accent: '#338daa',
    }
});
