<template>
  <nav>
    <v-app-bar app>
      <v-btn fab text @click="toggleNavDrawer = !toggleNavDrawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <img src="../assets/original.png" height="30px" alt="" />
    </v-app-bar>
    <v-navigation-drawer
      temporary
      class="accent pa-5"
      v-model="toggleNavDrawer"
      app
    >
      <v-img
        src="../assets/original_white.png"
        alt=""
        style="max-width: 100%"
      />
      <v-list>
        <v-list-item
          v-for="navLink in navLinks"
          :key="navLink.name"
          :href="navLink.link"
        >
          <v-list-item-icon class="justify-center">
            <v-icon class="white--text" right>{{ navLink.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title left class="white--text text-center">{{
              navLink.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      toggleNavDrawer: false,
      navLinks: [
        {
          name: "Evidensia",
          icon: "mdi-arrow-left",
          link: "https://www.ivcevidensia.nl",
        },
        {
          name: "Home",
          icon: "mdi-home",
          link: "/",
        },
        {
          name: "Over Ons",
          icon: "mdi-information",
          link: "/over-ons",
        },
        {
          name: "Diensten",
          icon: "mdi-briefcase",
          link: "/diensten",
        },
        {
          name: "Contact",
          icon: "mdi-phone",
          link: "/contact",
        },
      ],
    };
  },
};
</script>

<style>
</style>