<template>
  <div class="banner">
    <div class="name accent">
      {{ name }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "Evidensia Dierenziekenhuis Nieuwegein",
    };
  },
};
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  height: 60vh;
  background-image: url("https://WEU-AZ-WEB-NL-CDNEP.azureedge.net/mediacontainer/medialibraries/edznieuwegein/images/hero/hero_home3.jpg?ext=.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .name {
    z-index: 2;
    color: white;
    font-size: 1.5rem;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
</style>